import React from 'react'
import { CenteredResponsiveContainer, Layout } from '@/components/Layout'
import { SEO } from '@/components/SEO'
import tw from 'twin.macro'

const ukGenderPayGapReports: { [name: string]: string } = {
  '2024 UK Gender Pay Gap Disclosure': 'UK-Gender-Pay-Gap-Disclosure-2024.pdf',
  '2023 UK Gender Pay Gap Disclosure': 'UK-Gender-Pay-Gap-Disclosure-2023.pdf',
}
const GFXCAlgoDueDiligence = 'GFXC-Algo-Due-Diligence.pdf'
const FXGlobalCode = 'FX-Global-Code.pdf'
const disclosuresnov24 = 'disclosures-nov24.pdf'
const mssFilename = 'JTIL_Modern-Slavery-Statement-FYE-2023.pdf'

const Anchor = tw.a`text-jump-red`

export default function Disclosures() {
  return (
    <Layout>
      <SEO title="Disclosures" />
      <section>
        <CenteredResponsiveContainer>
          <h1 className="text-3xl mb-5">Disclosures</h1>

          <h3 className="text-2xl mb-5">Jump Trading, LLC / Jump Execution, LLC</h3>
          <div className="mb-5">
            <Anchor href={`/${disclosuresnov24}`}>Broker-dealer disclosures</Anchor>
          </div>

          <h3 className="text-2xl mb-5">Jump Trading Futures, LLC</h3>
          <div className="mb-5">
            <Anchor href={`/${GFXCAlgoDueDiligence}`}>
              FX Global Code Algo Due Diligence Template
            </Anchor>
          </div>
          <div className="mb-5">
            <Anchor href={`/${FXGlobalCode}`}>
              FX Global Code Liquidity Provider Disclosure Cover Sheet
            </Anchor>
          </div>

          <h3 className="text-2xl mb-5">Jump Trading Europe B.V.</h3>
          <div className="mb-5">
            A copy of our Pillar III disclosure can be obtained by written request to&nbsp;
            <Anchor href="mailto:compliance@jumptrading.com">compliance@jumptrading.com</Anchor>
          </div>

          <h3 className="text-2xl mb-5">Jump Trading International, Ltd.</h3>
          {ukGenderPayGapReports &&
            Object.entries(ukGenderPayGapReports).map(([reportName, filename]) => (
              <div className="mb-5" key={reportName}>
                <Anchor href={`/${filename}`}>{reportName}</Anchor>
              </div>
            ))}
          <div className="mb-5">
            <Anchor href={`/${mssFilename}`}>Modern Slavery Statement</Anchor>
          </div>
        </CenteredResponsiveContainer>
      </section>
    </Layout>
  )
}
